@font-face {
  font-family: 'vodafone';
  src: url('/fonts/vodafone/bold/vodafone.woff2') format('woff2'),
  url('/fonts/vodafone/bold/vodafone.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'vodafone';
  src: url('/fonts/vodafone/regular/vodafone.woff2') format('woff2'),
  url('/fonts/vodafone/regular/vodafone.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Font Families
$primary: (Arial, "Helvetica Neue", Helvetica, sans-serif);
$secondary: ("vodafone", Arial, "Helvetica Neue", Helvetica, sans-serif);

// Font weights
$normal: 400;
$bold: 700;
$light: 300;
