
@import './index.module.scss';

.Wrapper {
  background: white;
  position: relative;
  padding: 4.8em 0;
}

.SearchWrapper {
  background: white;
  position: relative;
  padding: 7em 0;
}

.Container {
  width: 90%;
  margin: auto;
  padding: 2em 0;
  overflow: hidden;

  @include max-bp($small) {
    width: 100%;
    margin: auto;
    padding: 2em 0;
    overflow: hidden;
  }
}

.class {
  padding: .2em;
}

.PostContent {
  width: 90%;
  margin: auto;
  position: relative;
  
  @include bp($large) {
    max-width: 1050px;
  }

  @include bp($larger) {
    display: flex;
    align-items: flex-start;
  }
}

.TakeoverContent{
  width: 90%;
  margin: auto;
  position: relative;

}

.View {
  position: absolute;
  bottom: 30em;
}

.NoResults {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6em;

  @include max-bp($small) {
    padding: 0 2em;
  }
  @include bp($medium) {
    min-height: 25vh;
  }
}