// This file imports all styling and gets injected into every stylesheet so variables can be available to you
// This is also added to pages/_app.js for global styling
// Checkout components/Organisms/Example/Example.scss

@import './susy.module.scss';
@import './colors.module.scss';
@import './fonts.module.scss';
@import './breakpoints.module.scss';
@import './layout.module.scss';


@mixin bp($screen) {
  @media (min-width: $screen + 'px') {
    @content;
  }
}

@mixin max-bp($screen) {
  @media (max-width: $screen - 1 +'px') {
    @content;
  }
}

@mixin container {
  max-width: 100%;
  margin: auto;
  background-image: linear-gradient(to right, rgba(102, 102, 255, 0.25), rgba(179, 179, 255, 0.25) 80%, transparent 80%);
  background-size: 10.20408%;
  background-origin: content-box;
  background-clip: content-box;
  background-position: left top;
}

// *[class$=-inner] {
//   max-width: 1280px;
//   padding: 0 1em;
//   margin: 0 auto;
// }

// commons
// .spacing-container {
//   margin: 4.375em auto;

//   @include bp($medium) {
//     margin: 7.5em auto;
//   }

//   &.noTop {
//     margin-top: 0;
//   }

//   &.noBottom {
//     margin-bottom: 0;
//   }
// }


// h1, h2, h3, h4, h5, h6 {
//   letter-spacing: .125em;
//   line-height: 1.3em;
//   // font-family: $info;
//   font-weight: 100;
//   text-transform: uppercase;
// }

// h1 {
//   font-size: 1.8125em;
//   font-weight: 100;
//   text-align: center;

//   @include bp($larger) {
//     font-size: 2.25em;
//   }
// }

// p, a {
//   letter-spacing: .0625em;
//   line-height: 1.5em;
//   font-size: 1em;
//   // font-family: $primary;


//   @include bp($medium) {
//     font-size: 1.125em;
//   }
// }

// p {
//   font-size: 1em;

//   @include bp($larger) {
//     font-size: 1.125em;
//   }
// }

// a {
//   font-size: .75em;
//   text-decoration: none;
//   cursor: pointer;
//   -webkit-tap-highlight-color: transparent;

//   &:focus {
//     outline: none !important;
//   }
// }

// a:focus,
// button:focus,
// input:focus,
// textarea:focus {
//   outline: none !important;
// }

// li {
//   list-style: none;
// }
