.cell {
  max-width: 83.05085%;
  margin-left: auto;
  margin-right: auto;
}

.well {
  padding-top: 1.45rem;
  padding-bottom: 1.45rem;
}

.well_l {
  padding-top: 2.9rem;
  padding-bottom: 2.9rem;
}

// lists

.inlineList {
  //margin: 0 0 -6px 0; //@todo: temporary
  padding: 0;
  margin: 0;
  list-style: none;
  & li {
    display: inline-block;
  }
}

// // Media Bucket
// .media {
//   @include clearfix-me(micro);
//   margin-top: vr(0.25);
//   margin-bottom: vr(0.25);
// }

// .mediaImg {
//   float: left;
//   margin-right: 10px;
//   > img {
//     display: block;
//   }
// }

// .mediaBody {
//   @include clearfix-me(facebook);
// }

// .mediaImgExt {
//   float: right;
//   margin-left: 10px;
//   margin-right: 0;
// }

.categoryTitle {
  margin-top: 45px;
  text-align: center;
}

.featuredCategory {
  width: 100%;
  padding-bottom: 25%;
  background: rgba(0,0,0,0.2);
}